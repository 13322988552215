<template>
  <div class="container grid-md">
    <div class="futurehunt__image-container">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="810.1px"
        height="728.1px"
        viewBox="0 0 810.1 728.1"
        style="enable-background:new 0 0 595.3 841.9;"
        xml:space="preserve"
      >
        <defs>
          <clipPath id="path">
            <path
              d="M104,202.7c16.4-52.1,33-131.5,89-172s239-36,316-15s297,164.3,301,367c3.7,189.2-85,295-282,334s-405-31-451-83s-77.9-69-77-212C0.5,348.7,87.6,254.9,104,202.7z"
            />
          </clipPath>
        </defs>
      </svg>
      <img :src="networkSVG" class="futurehunt__image" />
    </div>
    <div class="columns">
      <div class="column col-7 col-sm-12">
        <h1>{{ $t("futurehunt.intro") }}</h1>
      </div>
      <div class="column col-8 col-sm-12 col-offset-2 txt--center">
        <img
          class="img-responsive"
          src="../assets/images/futurehunt_process.png"
        />
        <h3 class="margin--s">{{ $t("futurehunt.statement_1_headline") }}</h3>
      </div>
      <div class="column col-10 col-sm-12 col-offset-1 txt--center">
        <div v-html="$t('futurehunt.text')"></div>
        <br />
        <a
          href="https://futurehunt.net/"
          target="_blank"
          class="button button--arrow-left"
          >{{ $t("futurehunt.website_link") }}</a
        >
      </div>
      <div class="column column--full-width-bg">
        <div
          class="column col-6 col-sm-12 col-md-10 col-lg-9 col-xl-8 txt--center"
        >
          <h4 v-html="$t('futurehunt.statement_2_headline')">"</h4>
          <router-link class="button button--arrow-left" to="/contact">{{
            $t("navigation.letstalk")
          }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FutureHunt",
  data() {
    return {
      networkSVG: require("../assets/images/futurehunt_visual.svg"),
    };
  },
};
</script>

<style lang="scss" scoped>
.futurehunt {
  &__image-container {
    margin-top: 2rem;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 40%;
    z-index: 0;
  }

  &__image {
    clip-path: url("#path");
    left: 0;
    position: absolute;
    top: 0;
  }
}

.column--full-width-bg {
  @include column--full-width-bg;
}

.columns {
  position: relative;
  z-index: 2;
}
</style>
